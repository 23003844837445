<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">Email address</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="payload.email" placeholder="Enter email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="payload.password" placeholder="Password" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">Sign in</button>
      </div>
      <!-- <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Already Have Account ?
            <router-link to="/auth/sign-in1" class="iq-waves-effect pr-4">
                Sign in
              </router-link>
          </span>
          <social-login-form></social-login-form>
      </div> -->
    </form>
  </ValidationObserver>
</template>

<script>
// import SocialLoginForm from './SocialLoginForm'
export default {
  name: 'SignIn1Form',
  // components: { SocialLoginForm },
  props: ['formType', 'email', 'password'],
  data: () => ({
    payload: {
      email: '',
      password: ''
    }
  }),
  methods: {
    onSubmit () {
      this.loginUser(this.payload).then(() => {
        this.$router.push('/slider')
      })
    }
  }
}
</script>
